<script lang="ts">import Select from 'svelte-select';
import { setContext, onMount } from 'svelte';
import { writable } from 'svelte/store';
import { darkMode } from '../utils/store';
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();
export let label = '';
export let items = [];
export let selectedValue;
export let placeholder = '';
export let noOptionsMessage = '';
export let getOptionLabel = (option, filterText) => {
    return option.isCreator ? `Create \"${filterText}\"` : option.label;
};
export let getSelectionLabel = (option) => {
    if (option)
        return option.label;
};
export let optionIdentifier = 'value';
export let isMulti = false;
export let isClearable = false;
export let showIndicator = true;
export let listPlacement = 'bottom';
export let disabled = false;
const themes = [
    {
        name: 'light',
        itemIsActiveBG: '#153f59',
        itemIsActiveColor: '#fff',
        itemHoverBG: '#38d3ff',
        itemHoverColor: '#fff',
        disabledPlaceholderColor: 'var(text-gray-300)',
        placeholderColor: 'var(text-gray-500)',
        listBackground: '#fff',
        multiItemActiveColor: '#fff',
        multiItemActiveBG: '#38d3ff',
        multiItemBG: '#ebedef',
    },
    {
        name: 'dark',
        itemIsActiveBG: '#38d3ff',
        itemIsActiveColor: '#fff',
        itemHoverBG: '#D1D5DB',
        itemHoverColor: '#000',
        disabledPlaceholderColor: 'var(text-gray-300)',
        placeholderColor: 'var(text-gray-500)',
        listBackground: '#374151',
        multiItemActiveColor: '#fff',
        multiItemActiveBG: '#38d3ff',
        multiItemBG: '#374151',
    },
];
let _current = themes[1].name; // set state of current theme's name
const getCurrentTheme = (name) => themes.find((h) => h.name === name); // utility to get current theme from name
const Theme = writable(getCurrentTheme(_current)); // set up Theme store, holding current theme object
setContext('theme', {
    // providing Theme store through context makes store readonly
    theme: Theme,
    toggle: () => {
        // update internal state
        let _currentIndex = themes.findIndex((h) => h.name === _current);
        _current =
            themes[_currentIndex === themes.length - 1 ? 0 : (_currentIndex += 1)]
                .name;
        // update Theme store
        Theme.update((t) => (Object.assign(Object.assign({}, t), getCurrentTheme(_current))));
        setRootColors(getCurrentTheme(_current));
    },
});
onMount(() => {
    // set CSS vars on mount
    setRootColors(getCurrentTheme(_current));
});
$: if ($darkMode) {
    _current = themes[1].name;
    Theme.update((t) => (Object.assign(Object.assign({}, t), getCurrentTheme(_current))));
    setRootColors(getCurrentTheme(_current));
}
else {
    _current = themes[0].name;
    Theme.update((t) => (Object.assign(Object.assign({}, t), getCurrentTheme(_current))));
    setRootColors(getCurrentTheme(_current));
}
// sets CSS vars for easy use in components
// ex: var(--theme-background)
const setRootColors = (theme) => {
    for (let [prop, val] of Object.entries(theme)) {
        if (prop != 'name') {
            let varString = `--themed-${prop}`;
            document.documentElement.style.setProperty(varString, val);
        }
    }
    document.documentElement.style.setProperty('--theme-name', theme.name);
};
function handleSelect(event) {
    dispatch('notify', event.detail);
}
</script>

{#if label != ''}
  <span class="text-small text-gray-600 dark:text-gray-400">{label}</span>
{/if}

<div class="themed cursor-pointer border-b border-gray-700 dark:text-gray-300">
  <Select
    {items}
    {showIndicator}
    {isClearable}
    {getOptionLabel}
    {getSelectionLabel}
    {optionIdentifier}
    {placeholder}
    {isMulti}
    {listPlacement}
    {noOptionsMessage}
    isDisabled={disabled}
    bind:value={selectedValue}
    on:select={handleSelect}
  />
</div>

<style>
  .themed {
    /* CSS variables below are used by the svelte-select component for custom styling */
    --height: 39px;
    --border: 0px solid transparent;
    /* --indicatorColor: #3b82f6; */
    --indicatorTop: 5px;
    --itemIsActiveBG: var(--themed-itemIsActiveBG);
    --itemIsActiveColor: var(--themed-itemIsActiveColor);
    --itemHoverBG: var(--themed-itemHoverBG);
    --itemHoverColor: var(--themed-itemHoverColor);
    /* --placeholderColor: #dbeafe; */
    --inputFontSize: 0.75rem;
    --background: transparent;
    --listBackground: var(--themed-listBackground);
    --disabledBackground: transparent;
    --disabledPlaceholderColor: var(--themed-disabledPlaceholderColor);
    --placeholderColor: var(--themed-placeholderColor);
    --multiItemActiveColor: var(--themed-multiItemActiveColor);
    --multiItemActiveBG: var(--themed-multiItemActiveBG);
    --multiItemBG: var(--themed-multiItemBG);
  }
  .themed :global(*) {
    text-align: left;
    font-size: 0.75rem;
  }
  .text-small {
    font-size: 0.65rem;
  }
</style>
