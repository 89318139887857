<script>
  import { darkMode, user, userSettings } from '../utils/store';
  import { db } from '../utils/services';

  function saveDarkMode() {
    db.collection('user_settings')
      .doc($user.uid)
      .set(
        {
          businessBuddy: {
            darkMode: !$darkMode,
          },
        },
        { merge: true }
      )
      .then(() => {
        console.log('successfully updated darkmode');
      })
      .catch((error) => {
        console.log('failed to update darkmode: ', error);
      });
  }
</script>

<div class="flex mt-8 items-center gap-4 text-xs">
  <i class="fas fa-sun text-socius-secondary dark:text-gray-400" />

  <label
    for="userType"
    class="flex items-center justify-between cursor-pointer"
  >
    <!-- toggle switch -->
    <div class="relative ml-1">
      <!-- invisible input -->
      <input
        bind:checked={$darkMode}
        on:input={() => saveDarkMode()}
        id="userType"
        type="checkbox"
        class="hidden"
      />
      <!-- line -->
      <div class="w-8 h-3 bg-gray-400 rounded-full shadow-inner" />
      <!-- dot -->
      <div
        class="toggle__dot absolute inset-y-0 left-0 w-5 h-5 bg-socius-secondary rounded-full shadow"
      />
    </div>
  </label>

  <i class="fas fa-moon text-gray-400 dark:text-socius-secondary" />
</div>

<style type="postcss">
  .toggle__dot {
    top: -0.25rem;
    left: -0.25rem;
    transition: all 0.25s ease-in-out;
  }

  input:checked ~ .toggle__dot {
    transform: translateX(100%);
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgb(56 211 255 / var(--tw-bg-opacity));
  }
</style>
